import Image from 'next/image';
import Head from 'next/head';

export default function custom404() {
  return (
    <>
      <Head>
        <title>404 - Page not found</title>
      </Head>
      <div className="h-screen">
        <div className="flex flex-col items-center justify-center max-w-screen h-screen">
          <Image
            priority
            src={'/assets/images/general-error.png'}
            height={200}
            width={270}
            alt="404 Not Found"
          />
          <h3 className="text-xl font-medium mt-2">404</h3>
          <h3 className="text-base font-medium">Page Not Found</h3>
        </div>
      </div>
    </>
  );
}
